import React, { useEffect, useState } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Form, Input, Button, message, Spin, Select } from "antd";
import DynamicMediaUploader from "../../assets/hooks/multiImagesUploder";
import { Get, Put } from "../../utils/services/request2";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCategoryList } from "../../apiFunctions/categories";
import { fetchOutletList } from "../../apiFunctions/outlets";
import { fetchPassList } from "../../apiFunctions/passes";
import moment from "moment";
import { preventToEnterSymbol, validateCategories } from "../../utils/functions/common";
import dayjs from "dayjs";
import DatePicker from 'react-date-picker';
import CustomAutoComplete from "../../components/inputs/autocomplete";


const EditBenefits = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [categoriesFromApi, setCategoriesFromApi] = useState([]);
  const [outletsFromApi, setOutletsFromApi] = useState([]);
  const [passesFromApi, setPassesFromApi] = useState([]);
  const [amenitiesFromApi, setAmenitiesFromApi] = useState([]);



  useEffect(() => {
    getBenefitByID();
  }, []);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        name: formData.name,
        arabic_name: formData.arabic_name,
        description: formData?.description,
        arabic_description: formData?.arabic_description,
        exclusion: formData?.exclusion,
        arabic_exclusion: formData?.arabic_exclusion,
        short_description: formData?.short_description,
        arabic_short_description: formData?.arabic_short_description,
        terms: formData?.terms,
        estimated_savings: formData?.estimated_savings,
        benefit_count: formData?.benefit_count,
        average_revenue: formData?.average_revenue,
        exclusion: formData?.exclusion,
        outlet_id: formData?.outlets?.map(v => v?.id),
        pass_id: formData?.passes?.map(v => v?.id),
        category_id: formData?.categories?.map(v => v?.id),
        amenity_id: formData?.amenities?.map(v => v?.id),
        end_date: dayjs(formData?.benefit_config?.end_date, "YYYY-MM-DD"),
        is_unlimited: formData?.benefit_config?.is_unlimited,
        primary_category: formData?.primary_category?.id,
        other_primary_category: formData?.other_primary_category?.map(v => v?.id),
      });

      if (formData.medias && formData?.medias?.length > 0) {
        const initialFileList = formData?.medias?.map((file, index) => ({
          id: file?.id, // Unique identifier for each file
          url: file?.path, // URL for the file preview
          size: file?.size
        }));
        setFileList(initialFileList);
      }
    }
  }, [formData, form]);

  const getBenefitByID = async () => {
    try {
      setLoading(true);
      const res = await Get(`${ApiRoutes.benefits}/${id}`);
      const dataToSet = res?.data?.data;
      const otherPrimaryCategory = dataToSet?.categories?.filter(v => v?.instore_benefit_categories?.is_other_primary) ?? [];
      const primaryCategory = dataToSet?.categories?.find(v => v?.instore_benefit_categories?.is_primary) ?? null;
      dataToSet.other_primary_category = otherPrimaryCategory;
      dataToSet.primary_category = primaryCategory;
      setFormData(dataToSet);
      setPassesFromApi(res?.data?.data?.passes?.map(v => ({ label: v?.name, value: v?.id })) ?? []);
      setCategoriesFromApi(res?.data?.data?.categories?.map(v => ({ label: v?.name, value: v?.id })) ?? []);
      setOutletsFromApi(res?.data?.data?.outlets?.map(v => ({ label: v?.name, value: v?.id })) ?? []);
      setAmenitiesFromApi(res?.data?.data?.amenities?.map(v => ({ label: v?.name, value: v?.id })) ?? []);
      setLoading(false);
    } catch (err) {
      message.error("Something went wrong!");
      setLoading(false);
    }
  };
  console.log("formData ----------->>>>>", passesFromApi);
  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const formDataToSubmit = {
      ...values,
      media: fileList?.map(v => (v?.id ? { path: v?.url, size: v?.size ?? 1, id: v?.id } : { path: v?.url, size: v?.size ?? 1, })),
      end_date: moment(new Date(values.end_date)).format("YYYY-MM-DD"),
      estimated_savings: Number(values.estimated_savings),
      benefit_count: Number(values.benefit_count),
      average_revenue: Number(values.average_revenue),
      benefit_value: Number(values.benefit_value),
      other_primary_category: values.other_primary_category || [],
      category_id: values.category_id ?? [],
    };
    const errors = validateCategories(formDataToSubmit)
    if (!errors) {

      try {
        const res = await Put(`${ApiRoutes.benefits}/${id}`, formDataToSubmit);
        setLoading(false);
        message.success("Benefit updated successfully!");
        navigate("/benefits");
      } catch (err) {
        message.error("Something went wrong!");
        setLoading(false);
      }
    } else {
      if (errors) {
        errors.forEach(([field1, field2]) => {
          form.setFields([
            {
              name: field1,
              errors: ["This field has a conflict with another field."],
            },
            {
              name: field2,
              errors: ["This field has a conflict with another field."],
            },
          ]);
        });
        setLoading(false);
        return;
      }
    }
  };

  const getUploadedList = (list) => {
    console.log(list, "Uploaded Files");
  };

  const fetchOutlets = async (value) => {
    try {
      const outlets = await fetchOutletList(value ? { name: value } : {});
      const outletsToReturn = outlets?.data?.records?.map(v => ({ label: v?.name, value: v?.id })) ?? []
      outletsFromApi.forEach(v => {
        if (!outletsToReturn.find(v2 => v2.value === v.value) && value) {
          outletsToReturn.push(v)
        }
      })
      return outletsToReturn
    } catch (error) {
      return [];
    }
  }

  const fetchCategories = async (value) => {
    try {
      const categories = await fetchCategoryList(value ? { name: value } : {});
      const categoriesToReturn = categories?.data?.records?.map(v => ({ label: v?.name, value: v?.id })) ?? []
      categoriesFromApi.forEach(v => {
        if (!categoriesToReturn.find(v2 => v2.value === v.value) && !value) {
          categoriesToReturn.push(v)
        }
      })
      return categoriesToReturn;
    } catch (error) {
      return [];
    }
  }

  const fetchAmenities = async (value) => {
    try {
      const amenities = await Get(ApiRoutes.amenities, null, value ? { name: value } : {});
      const amenitiesToReturn = amenities?.data?.data?.records?.map(v => ({ label: v?.name, value: v?.id })) ?? []
      amenitiesFromApi.forEach(v => {
        if (!amenitiesToReturn.find(v2 => v2.value === v.value) && !value) {
          amenitiesToReturn.push(v)
        }
      })
      return amenitiesToReturn;
    } catch (error) {
      return [];
    }
  }

  const fetchPasses = async (value) => {
    try {
      const passes = await fetchPassList(value ? { name: value } : {});
      const passesToReturn = passes?.data?.records?.map(v => ({ label: v?.name, value: v?.id })) ?? []
      passesFromApi.forEach(v => {
        if (!passesToReturn.find(v2 => v2.value === v.value) && !value) {
          passesToReturn.push(v)
        }
      })
      return passesToReturn;
    } catch (error) {
      return [];
    }
  }


  const redeem = () => {
    return (
      <Spin spinning={loading}>
        <Form
          form={form}
          name="custom-form"
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: "600px", margin: "50px auto" }}
        >
          <Button
            className="mb-10"
            onClick={() => {
              navigate("/benefits");
            }}
            type="primary"
          >
            Back
          </Button>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please Enter Name!" }]}
          >
            <Input placeholder="Enter Name" />
          </Form.Item>

          <Form.Item
            label="Arabic Name"
            name="arabic_name"
          >
            <Input placeholder="Enter Arabic Name" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
          >
            <Input placeholder="Enter Description" />
          </Form.Item>

          <Form.Item
            label="Arabic Description"
            name="arabic_description"
          >
            <Input placeholder="Enter Arabic Description" />
          </Form.Item>

          <Form.Item
            label="Exclusion"
            name="exclusion"
          >
            <Input placeholder="Enter Exclusion" />
          </Form.Item>

          <Form.Item
            label="Arabic Exclusion"
            name="arabic_exclusion"
          >
            <Input placeholder="Enter Arabic Exclusion" />
          </Form.Item>

          <Form.Item
            label="Short Description"
            name="short_description"
          >
            <Input placeholder="Enter Short Description" />
          </Form.Item>

          <Form.Item
            label="Short Arabic Description"
            name="short_arabic_description"
          >
            <Input placeholder="Enter Short Arabic Description" />
          </Form.Item>

          <Form.Item
            label="Terms"
            name="terms"
          >
            <Input placeholder="Enter Terms" />
          </Form.Item>

          <Form.Item
            label="Estimated Savings"
            name="estimated_savings"
          >
            <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Estimated Savings" />
          </Form.Item>

          <Form.Item
            label="Benefit Count"
            name="benefit_count"
            rules={[{ required: true, message: "Please Enter Benefit Count!" }]}
          >
            <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Benefit Count" />
          </Form.Item>

          <Form.Item
            label="Average Revenue"
            name="average_revenue"
          >
            <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Average Revenue" />
          </Form.Item>

          <Form.Item
            label="Benefit Value"
            name="benefit_value"
            rules={[{ required: true, message: "Please Enter Benefit Value!" }]}
          >
            <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Benefit Value" />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="end_date"
            rules={[{ required: true, message: "Please Enter End Date!" }]}
          >
            <DatePicker className="w-full" />
          </Form.Item>

          <Form.Item
            label="Is Unlimited"
            name="is_unlimited"
            rules={[{ required: true, message: "Please Select Is Unlimited!" }]}
          >
            <Select
              className='antd__inp'
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              placeholder="Is Unlimited"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            label="Primary Category"
            name="primary_category"
            rules={[{ required: true, message: "Please Select Primary Category!" }]}
          >
            <CustomAutoComplete placeholder={"Select Primary Category"} name={'primary_category'} fetchData={fetchCategories} form={form} />
          </Form.Item>

          <Form.Item
            label="Category"
            name="category_id"
          >
            <CustomAutoComplete multiple placeholder={"Select Category"} name={'category_id'} fetchData={fetchCategories} form={form} />
          </Form.Item>

          <Form.Item
            label="Other Primary Category"
            name="other_primary_category"
          >
            <CustomAutoComplete multiple placeholder={"Select Other Primary Categories"} name={'other_primary_category'} fetchData={fetchCategories} form={form} />
          </Form.Item>

          <Form.Item
            label="Amenity"
            name="amenity_id"
            rules={[{ required: true, message: "Please Select Amenities!" }]}
          >
            <CustomAutoComplete
              multiple
              placeholder={"Select Amenities"}
              name={'amenity_id'}
              fetchData={fetchAmenities}
              form={form}
            />
          </Form.Item>

          <Form.Item
            label="Pass"
            name="pass_id"
            rules={[{ required: true, message: "Please Select Pass!" }]}
          >
            <CustomAutoComplete multiple placeholder={"Select Passes"} name={'pass_id'} fetchData={fetchPasses} form={form} />
          </Form.Item>

          <Form.Item
            label="Outlet"
            name="outlet_id"
            rules={[{ required: true, message: "Please Select Outlets!" }]}
          >
            <CustomAutoComplete multiple name={"outlet_id"} fetchData={fetchOutlets} form={form} />
          </Form.Item>

          <Form.Item label="Media Files">
            <DynamicMediaUploader setFileList={setFileList} fileList={fileList} />
          </Form.Item>
          <Form.Item>
            <Button
              className="w-full"
              disabled={loading}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default EditBenefits;
