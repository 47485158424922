//
import React, { useEffect, useState, useCallback } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Form, Input, Button, message, Select } from "antd";
import MultiImageUploader from "../../assets/hooks/multiImagesUploder";
import DynamicMediaUploader from "../../assets/hooks/multiImagesUploder";
import { Post } from "../../utils/services/request2";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { useNavigate } from "react-router-dom";
import { fetchMerchantList } from "../../apiFunctions/merchants";
import { fetchCategoryList } from "../../apiFunctions/categories";
import CustomAutoComplete from "../../components/inputs/autocomplete";

const AddOutlet = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [merchantOptions, setMerchantOptions] = useState([]);

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = {
      ...values,
      media: fileList?.map(v => ({ path: v?.url,size: v?.size ?? 1 })),
    };

    try {
      const res = await Post(ApiRoutes.createOutlet, formData);
      setLoading(false);
      message.success("Outlet added successfully!");
      form.resetFields();
      setFileList([]);
      navigate("/outlets");
    } catch (err) {
      message.error("something went wrong!");
      setLoading(false);
    }
  };

  const getUploadedList = (list) => {
    console.log(list, "imtiaz");
  };

  const fetchMerchants = async (value) => {
    try {
      const res = await fetchMerchantList(value ? { name: value } : {});
      return res?.data?.records?.map((v) => ({ label: v?.name, value: v?.id }));
    } catch (error) {
      return [];
    }
  }

  const fetchCategories = async (text) => {
    const [categories] = await Promise.all([fetchCategoryList(text ? {name:text}:{})]);
    const categoryOptions = categories?.data?.records?.map(v => ({ label: v?.name, value: v?.id }));
    return categoryOptions;
  }


  const redeem = () => {
    return (
      <Form
        form={form}
        name="custom-form"
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: "600px", margin: "50px auto" }}
      >
        <Button
          className="mb-10"
          onClick={() => {
            navigate("/outlets");
          }}
          type="primary"
        >
          Back
        </Button>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please Enter Name!" },
            { min: 3, message: "Name must be at least 3 characters!" },
            { max: 50, message: "Name cannot exceed 50 characters!" },
          ]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          label="Arabic Name"
          name="arabic_name"
        >
          <Input placeholder="Enter Arabic Name" />
        </Form.Item>

        <Form.Item
          label="Merchant"
          name="merchant_id"
          rules={[{ required: true, message: "Please Select a Merchant!" }]}
        >
         <CustomAutoComplete placeholder={"Select Merchant"} name={'merchant_id'} fetchData={fetchMerchants} form={form} />
        </Form.Item>

        <Form.Item
          label="Categories"
          name="categories"
          rules={[{ required: true, message: "Please Select Categories!" }]}
        >
          <CustomAutoComplete placeholder={"Select Categories"} name={'categories'} multiple fetchData={fetchCategories} form={form} />
        </Form.Item>

        <Form.Item label="Media Files">
        <DynamicMediaUploader setFileList={setFileList} fileList={fileList} />
        </Form.Item>
        <Form.Item>
          <Button
            className="w-full"
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default AddOutlet;
