//
import React, { useEffect, useState, useCallback } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Form, Input, Button, message, Select } from "antd";
import MultiImageUploader from "../../assets/hooks/multiImagesUploder";
import DynamicMediaUploader from "../../assets/hooks/multiImagesUploder";
import { Post } from "../../utils/services/request2";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { useNavigate } from "react-router-dom";
import { InputWithlabel } from "../../components";
import { fetchPartnerList } from "../../apiFunctions/partners";
import { preventToEnterSymbol, preventToEnterSymbolAndZero } from "../../utils/functions/common";
import moment from "moment";
import { CURRENCY_LIST } from "../../utils/constants";
import DatePicker from 'react-date-picker';
import CustomAutoComplete from "../../components/inputs/autocomplete";


const AddPass = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = {
      ...values,
      media: fileList?.map(v => ({ path: v?.url, size: 1 })),
      estimated_savings: Number(values.estimated_savings),
      price: Number(values.price),
      discounted_price: Number(values.discounted_price),
      start_date: moment(new Date(values.start_date)).format("YYYY-MM-DD"),
      end_date: moment(new Date(values.end_date)).format("YYYY-MM-DD"),
      is_free: null,
      priority: Number(values.priority),
    };

    try {
      const res = await Post(ApiRoutes.createPass, formData);
      setLoading(false);
      message.success("Pass added successfully!");
      form.resetFields();
      setFileList([]);
      navigate("/passes");
    } catch (err) {
      message.error("something went wrong!");
      setLoading(false);
    }
  };

  const getUploadedList = (list) => {
    console.log(list, "imtiaz");
  };

  const getPartners = async (text) => {
    const { data } = await fetchPartnerList(text ? { name: text } : {});
    return data?.records?.map(v => ({ label: v?.name, value: v?.id }))
  }

  useEffect(() => {
  }, [])

  const redeem = () => {
    return (
      <Form
        form={form}
        name="custom-form"
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: "600px", margin: "50px auto" }}
      >
        <Button
          className="mb-10"
          onClick={() => {
            navigate("/passes");
          }}
          type="primary"
        >
          Back
        </Button>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please Enter Name!" }]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          label="Arabic Name"
          name="arabic_name"
        >
          <Input placeholder="Enter Arabic Name" />
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Please Enter Price!" }]}
        >
          <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Price" />
        </Form.Item>
        <Form.Item
          label="Discounted Price"
          name="discounted_price"
        >
          <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Discounted Price" />
        </Form.Item>
        <Form.Item
          label="Currency"
          name="currency"
          rules={[{ required: true, message: "Please Enter Currency!" }]}
        >
          {/* <Input placeholder="Enter Currency" /> */}
          <Select
            className='antd__inp'
            options={CURRENCY_LIST}
            placeholder="Select Currency"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Estimated Savings"
          name="estimated_savings"
        >
          <Input type="number" min={0} onKeyDown={preventToEnterSymbol} placeholder="Enter Estimated Savings" />
        </Form.Item>
        <Form.Item
          label="Start Date"
          name="start_date"
          rules={[{ required: true, message: "Please Enter Start Date!" }]}
        >
          <DatePicker placeholder="Select Start Date" className="w-full" />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="end_date"
          rules={[{ required: true, message: "Please Enter End Date!" }]}
        >
          <DatePicker placeholder="Select End Date" className="w-full" />
        </Form.Item>
        <Form.Item
          label="Free"
          name="is_free"
        >
          <Select
            className='antd__inp'
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            placeholder="Select Free"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Purchase"
          name="is_purchase"
        >
          <Select
            className='antd__inp'
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            placeholder="Select Free"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Active"
          name="is_active"
        >
          <Select
            className='antd__inp'
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            placeholder="Select Active"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Partners"
          name="partner_id"
          rules={[{ required: true, message: "Please Select Partners!" }]}
        >
          <CustomAutoComplete placeholder={"Select Partners"} name={'partner_id'} fetchData={getPartners} form={form} multiple />
        </Form.Item>
        <Form.Item
          label="Priority"
          name="priority"
          rules={[{ required: true, message: "Please Enter Priority!" }]}
        >
          <Input type="number" min={1} onKeyDown={preventToEnterSymbolAndZero} placeholder="Enter Priority" />
        </Form.Item>

        <Form.Item label="Media Files">
          <DynamicMediaUploader setFileList={setFileList} fileList={fileList} />
        </Form.Item>
        <Form.Item>
          <Button
            className="w-full"
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default AddPass;
