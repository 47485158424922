import moment from "moment"
import { message } from "antd"

export const formateDate = (data) => {
    return moment(data).format("MM/DD/YY")
}

export const successMessage = (msg) => {
    message.success(msg);
}

export const errorMessage = (msg) => {
    message.error(msg);
}

export const preventToEnterSymbol = (e) => {
    if (e.key === '-' || e.key === '+') {
        e.preventDefault();
    }
}

export const preventToEnterSymbolAndZero = (e) => {
    if (e.key === '-' || e.key === '+' || e.key === '0') {
        e.preventDefault();
    }
}

export   function validateCategories(data) {
    const errors = [];
    const { category_id, primary_category, other_primary_category } = data;

    // Check if primary_category and other_primary_category are the same
    if (primary_category === other_primary_category?.[0] || primary_category === other_primary_category?.[1]) {
      errors.push(["primary_category", "other_primary_category"]);
    }

    // Check if primary_category exists in category_id array
    if (category_id?.includes(primary_category)) {
      errors.push(["primary_category", "category_id"]);
    }

    // Check if other_primary_category exists in category_id array
    other_primary_category?.forEach((otherCat) => {
      if (category_id.includes(otherCat)) {
        errors.push(["other_primary_category", "category_id"]);
      }
    });

    return errors.length > 0 ? errors : null;
  }