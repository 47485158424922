//
import React, { useEffect, useState, useCallback } from "react";
import Wrapper from "../../layouts/wrapper/wrapper";
import { Form, Input, Button, message } from "antd";
import MultiImageUploader from "../../assets/hooks/multiImagesUploder";
import DynamicMediaUploader from "../../assets/hooks/multiImagesUploder";
import { Post } from "../../utils/services/request2";
import ApiRoutes from "../../utils/apiRoutes/routes";
import { useNavigate } from "react-router-dom";

const AddCategories = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFileChange = (newFileList) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = {
      ...values,
      media: fileList?.map(v => ({ path: v?.url, size: 1 })),
    };

    try {
      const res = await Post(ApiRoutes.createCategory, formData);
      setLoading(false);
      message.success("Categry added successfully!");
      form.resetFields();
      setFileList([]);
      navigate("/categories");
    } catch (err) {
      message.error("something went wrong!");
      setLoading(false);
    }
  };

  const getUploadedList = (list) => {
    console.log(list, "");
  };

  const redeem = () => {
    return (
      <Form
        form={form}
        name="custom-form"
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: "600px", margin: "50px auto" }}
      >
        <Button
          className="mb-10"
          onClick={() => {
            navigate("/categories");
          }}
          type="primary"
        >
          Back
        </Button>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please Enter Name!" }]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          label="Arabic Name"
          name="arabic_name"
        >
          <Input placeholder="Enter Arabic Name" />
        </Form.Item>

        <Form.Item label="Media Files">
        <DynamicMediaUploader setFileList={setFileList} fileList={fileList} />
        </Form.Item>
        <Form.Item>
          <Button
            className="w-full"
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return <Wrapper Comp={redeem} />;
};

export default AddCategories;
